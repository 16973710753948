import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  ChecklistsBooleanAttrsService,
  ChecklistsDateAttrsService,
  ChecklistsDictionaryAttrsService,
  ChecklistsDoubleAttrsService,
  ChecklistsEnumAttrsService,
  ChecklistsIntegerAttrsService,
  ChecklistsLongStringAttrsService,
  ChecklistsStringAttrsService,
  ChecklistsUserDictionaryAttrsService,
} from '../attributes';
import { ISelectOption } from '../../../../../../../../../types/selectOption';

@provide.transient()
class ChecklistsAttrOptionsService {
  @lazyInject(ChecklistsBooleanAttrsService)
  protected booleanAttrsService: ChecklistsBooleanAttrsService;

  @lazyInject(ChecklistsIntegerAttrsService)
  protected integerAttrsService: ChecklistsIntegerAttrsService;

  @lazyInject(ChecklistsDoubleAttrsService)
  protected doubleAttrsService: ChecklistsDoubleAttrsService;

  @lazyInject(ChecklistsStringAttrsService)
  protected stringAttrsService: ChecklistsStringAttrsService;

  @lazyInject(ChecklistsDictionaryAttrsService)
  protected dictionaryAttrsService: ChecklistsDictionaryAttrsService;

  @lazyInject(ChecklistsEnumAttrsService)
  protected enumAttrsService: ChecklistsEnumAttrsService;

  @lazyInject(ChecklistsUserDictionaryAttrsService)
  protected userDictionaryAttrsService: ChecklistsUserDictionaryAttrsService;

  @lazyInject(ChecklistsLongStringAttrsService)
  protected longStringAttrsService: ChecklistsLongStringAttrsService;

  @lazyInject(ChecklistsDateAttrsService)
  protected dateAttrsService: ChecklistsDateAttrsService;

  setSelectOptionList = <T extends EAttrType>(
    type: T,
    groupId: string,
    id: string,
    selectOptionList: ISelectOption[]
  ): void => {
    switch (type) {
      case EAttrType.DictionaryLink:
        this.dictionaryAttrsService.setOptionList(groupId, id, selectOptionList);
        break;

      case EAttrType.Enum:
        this.enumAttrsService.setOptionList(groupId, id, selectOptionList);
        break;

      case EAttrType.UserDictionaryLink:
        this.userDictionaryAttrsService.setOptionList(groupId, id, selectOptionList);
        break;

      default:
    }
  };

  setSelectedSelectOptionList = <T extends EAttrType>(
    type: T,
    groupId: string,
    id: string,
    selectOptionList: ISelectOption[]
  ): void => {
    switch (type) {
      case EAttrType.DictionaryLink:
        this.dictionaryAttrsService.setSelectedOptionList(groupId, id, selectOptionList);
        break;

      case EAttrType.Enum:
        this.enumAttrsService.setSelectedOptionList(groupId, id, selectOptionList);
        break;

      case EAttrType.UserDictionaryLink:
        this.userDictionaryAttrsService.setSelectedOptionList(groupId, id, selectOptionList);
        break;

      default:
    }
  };
}

export default ChecklistsAttrOptionsService;
