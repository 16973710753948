import _ from 'lodash';
import moment from 'moment';

import {
  IGetChecklistAttributeListPayload,
  IPostAttributeValuesPayload,
  TGetChecklistByKeysPayload,
  TGetDictListByRemoteNameReq,
} from '../../../../../api/api';
import {
  EChecklistAttributeType,
  IChecklistAttributeWithValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
  IGetChecklistAttributeUserDictionary,
  IPutChecklistAttributeValue,
} from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IDrawingNestedInstance,
  IGetChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import {
  IChecklistDrawingStage,
  IGetChecklistStage,
} from '../../../../../api/models/checklist/stage/checklist.stage.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { ISelectOption } from '../../../../../types/selectOption';
import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { TasksEditStore } from '../../operations/stores/task.edit.store';
import { TasksStore } from '../../operations/stores/tasks.store';
import {
  checklistModalUniqueKey,
  EChecklistModalName,
} from '../modules/fullscreen/checklist/modals/modalsConfig';
import {
  checklistAttrChangeHelpers,
  checklistAttrTypeHelpers,
  userDictLinkHelpers,
} from '../modules/fullscreen/checklist/utils/attribute/helpers';
import { ChecklistsService } from '../services/checklists/checklists.service';
import { ChecklistFileUploaderStore } from '../stores/checklist.fileUploader.store';
import { ChecklistInstancesStore } from '../stores/checklist.instances.store';
import {
  createListOfDepAttrVal,
  DEFAULT_INTENSITY_OPTION,
  ESaveInstanceAttributeValuesResults,
  generateAttributeWithValueList,
  generateDrawingNestedInstance,
  generateDrawingStage,
  getValidatedAttrWithValueList,
  THandleChangeChecklistAttributeValue,
} from '../utils/checklist.instances';
import {
  checklistInstanceHelpers,
  drawChecklistInstanceHelpers,
} from '../utils/helpers/checklists';
import { FieldsService, TasksService } from '../../../../shared/mobx/services/as-fields';
import useInspectionPointsActionsHook from '../../tasks/components/InspectionPoints/hooks/useInspectionPointsActions/useInspectionPointsActions.hook';
import { ChecklistsAttrsDisplayService as AttrsDisplayService } from '../../tasks/modules/Checklists/mobx/services';
import { ERequestStatus } from '../../../../shared/constants/requests';
import { EChecklistInstanceEventType } from '../../../../../api/models/checklist/instance/events/checklist.instance.event.model';
import { ChecklistsStore } from '../../tasks/modules/Checklists/mobx/stores';
import checklistsStore from '../../tasks/modules/Checklists/mobx/stores/ChecklistsStore/Checklists.store';

import { IBreadcrumbsEntity } from './../../../../../api/models/breadcrumbs/breadcrumbs.model';

@provide.singleton()
export class ChecklistInstancesController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(AttrsDisplayService)
  protected attrsDisplayService: AttrsDisplayService;

  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(TasksEditStore)
  protected tasksEditStore: TasksEditStore;

  @lazyInject(ChecklistFileUploaderStore)
  protected checklistFileUploaderStore: ChecklistFileUploaderStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  fetchInstanceData = async (
    selectedInstId: string
  ): Promise<{ checklist: IGetChecklist; instance: IGetChecklistInstance }> => {
    if (!selectedInstId) {
      return;
    }

    const { setSelectedInstance, setSelectedIntensity } = this.checklistInstancesStore;

    console.log('===== fetchInstanceData');
    console.trace();

    // Избавляемся от выбранных элементов при смене точки
    this.clearStoreAfterChangedInstance();

    // Очищаем данные выбранной точки в сторе по работе с чек-листом.
    this.checklistsStore.clearSelectedChecklist();

    this.checklistsStore.clearAttrsToDrawByIdByGroupId();
    this.checklistsStore.clearNestedInstancesToDrawByIdByAttrId();
    this.checklistsStore.clearAttrIdToGroupId();

    this.checklistsStore.clearAttrIdWithError();
    this.checklistsStore.clearLastEditedAttrId();
    this.checklistsStore.clearIsAttrIdWithErrorTargeted();

    // Загружаем данные точки
    const instance = await this.fetchInstanceById(selectedInstId);

    if (instance) {
      setSelectedInstance(instance);
    }

    if (!instance?.checkListId) {
      return;
    }

    // Если у точки есть фенофаза, то делаем ее выбранной.
    if (instance?.intensity) {
      setSelectedIntensity(instance.intensity);
    }

    // Если у точки заполнен чек-лист, то получаем и его
    const fetchedChecklist = await this.fetchChecklistById(instance.checkListId);

    if (!fetchedChecklist) {
      return;
    }

    this.checklistsStore.setSelectedChecklist(fetchedChecklist);

    return { checklist: fetchedChecklist, instance };
  };

  fetchInstanceById = async (instanceId: string): Promise<IGetChecklistInstance> => {
    const { getExtendedInstance, setExtendedInstance } = this.checklistInstancesStore;

    const { fetchInstanceById } = this.checklistsService;

    // const previouslyFetchedInstance = getExtendedInstance(instanceId);

    // if (previouslyFetchedInstance) return previouslyFetchedInstance;

    const fetchedInstance = await fetchInstanceById(instanceId);

    if (!fetchedInstance) return;

    setExtendedInstance(fetchedInstance);

    return fetchedInstance;
  };

  fetchChecklistByKeys = async (payload: TGetChecklistByKeysPayload): Promise<IGetChecklist> => {
    const { fetchChecklistByKeys } = this.checklistsService;

    const fetchedChecklist = await fetchChecklistByKeys(payload);

    if (!fetchedChecklist) {
      return;
    }

    this.checklistsStore.setSelectedChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchChecklistById = async (checklistId: string): Promise<IGetChecklist> => {
    const { getChecklist } = this.checklistInstancesStore;

    const { fetchChecklistById } = this.checklistsService;

    const previouslyFetchedChecklist = getChecklist(checklistId);

    if (previouslyFetchedChecklist) return previouslyFetchedChecklist;

    const fetchedChecklist = await fetchChecklistById(checklistId);

    if (!fetchedChecklist) return;

    this.checklistsStore.setSelectedChecklist(fetchedChecklist);

    return fetchedChecklist;
  };

  fetchStageList = async (checklistId: string): Promise<IGetChecklistStage[]> => {
    const { getStageList, setStageList } = this.checklistInstancesStore;

    const { fetchStageList } = this.checklistsService;

    const previouslyFetchedStageList = getStageList(checklistId);

    if (previouslyFetchedStageList) return previouslyFetchedStageList;

    const fetchedStageList = await fetchStageList(checklistId);

    if (!fetchedStageList) return;

    setStageList(checklistId, fetchedStageList);

    return fetchedStageList;
  };

  fetchAttributeList = async (
    payload: IGetChecklistAttributeListPayload
  ): Promise<IGetChecklistAttribute[]> => {
    const { getAttributeList, setAttributeList } = this.checklistInstancesStore;

    const { fetchAttributeList } = this.checklistsService;

    const previouslyFetchedAttributeList = getAttributeList(payload.checkListId);

    if (previouslyFetchedAttributeList) return previouslyFetchedAttributeList;

    const fetchedAttributeList = await fetchAttributeList(payload);

    if (!fetchedAttributeList) return;

    setAttributeList(payload.checkListId, fetchedAttributeList);

    return fetchedAttributeList;
  };

  fetchAttributesByCheckListPublicId = async (
    publicId: string,
    payload?: TypeApiRequest<'getChecklistAttributeByCheckListPublicId'>
  ): Promise<IGetChecklistAttribute[]> => {
    const { getAttributeList, setAttributeList } = this.checklistInstancesStore;

    const { fetchAttributesByCheckListPublicId } = this.checklistsService;

    const previouslyFetchedAttributeList = getAttributeList(publicId);

    if (previouslyFetchedAttributeList) return previouslyFetchedAttributeList;

    const fetchedAttributeList = await fetchAttributesByCheckListPublicId(publicId, payload);

    if (!fetchedAttributeList) return;

    setAttributeList(publicId, fetchedAttributeList);

    return fetchedAttributeList;
  };

  fetchEnumListByAttributeId = async (
    attributeId: string
  ): Promise<IGetChecklistAttributeEnumValue[]> => {
    const { getAttributeEnumList, setEnumList } = this.checklistInstancesStore;

    const { fetchEnumListByAttributeId } = this.checklistsService;

    const previouslyFetchedEnumList = getAttributeEnumList(attributeId);

    if (previouslyFetchedEnumList) return previouslyFetchedEnumList;

    const fetchedEnumList = await fetchEnumListByAttributeId(attributeId);

    if (!fetchedEnumList) return;

    setEnumList(attributeId, fetchedEnumList);

    return fetchedEnumList;
  };

  // Intensity REST method
  fetchIntensityByTaskId = async (
    payload: TypeApiRequest<'getIntensityByTaskId'>
  ): Promise<IGetIntensity[]> => {
    const { setIdToIntensity } = this.checklistInstancesStore;

    try {
      const fetchedIntensityList = await this.axios.api.getIntensityByTaskId(payload, {
        omit: ['taskId'],
      });

      fetchedIntensityList.forEach(intensity => setIdToIntensity(intensity.id, intensity));

      return fetchedIntensityList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchUserDictionaryListByAttributeId = async (
    attributeId: string,
    organizationId: string
  ): Promise<IGetChecklistAttributeUserDictionary[]> => {
    try {
      const { setAttributeIdToUserDictionaryList } = this.checklistInstancesStore;

      const { getStoredValueList } = userDictLinkHelpers;

      const { content } = await this.axios.api.getChecklistUserDictionaryListByAttributeId(
        { attributeId, size: 2000, organizationId },
        { omit: ['attributeId'] }
      );

      setAttributeIdToUserDictionaryList(attributeId, [
        ...content,
        ...getStoredValueList(attributeId),
      ]);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchDictionaryListByRemoteName = async (
    attributeId: string,
    payload: TGetDictListByRemoteNameReq = {}
  ): Promise<IGetDictionary[]> => {
    try {
      const { setDictionaryList } = this.checklistInstancesStore;

      const { content } = await this.axios.api.getDictionaryListByRemoteName(payload, {
        omit: ['remoteName'],
      });

      setDictionaryList(attributeId, content);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchDictionaryItemBreadcrumbs = async (
    remoteName: string,
    id: string
  ): Promise<IBreadcrumbsEntity> => {
    try {
      const breadcrumbs = await this.axios.api.getDictionaryItemBreadcrumbs(
        { remoteName, id },
        {
          omit: ['remoteName', 'id'],
        }
      );

      return breadcrumbs;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchEventList = async (id: string) => {
    const { fetchChecklistInstanceEventList } = this.checklistsService;
    const { setSelectedInstanceEventList } = this.checklistInstancesStore;

    const eventList = await fetchChecklistInstanceEventList({
      id,
      typeEvent: EChecklistInstanceEventType.UpdateChecklist,
    });

    const sortedEventList = eventList.sort(
      (eventA, eventB) => moment(eventA.creationDate).unix() - moment(eventB.creationDate).unix()
    );

    setSelectedInstanceEventList(sortedEventList);
  };

  delEmptyTechnique = async (isDeleteAnyway?: boolean): Promise<void> => {
    const { listOfDrawMachineryInst, selectedInstance } = this.checklistInstancesStore;

    const emptyTechnique = listOfDrawMachineryInst.find(
      drawInst => !drawInst?.instance?.machineryName
    );

    if (emptyTechnique) {
      if (isDeleteAnyway) {
        await this.deleteInstance(emptyTechnique.id);

        return;
      }

      const isNotTechnique = emptyTechnique.id !== selectedInstance.id;

      if (isNotTechnique) {
        await this.deleteInstance(emptyTechnique.id);
      }
    }
  };

  deleteInstance = async (
    id: string,
    options?: {
      isChangesCanBeUndone?: boolean;
      displayPointList?: ReturnType<typeof useInspectionPointsActionsHook>['displayPointList'];
    }
  ): Promise<boolean> => {
    const { listOfDrawPointInst, setIdToDrawInst, delDrawInst } = this.checklistInstancesStore;

    const { deleteChecklistInstance } = this.checklistsService;

    const { createChangedDrawInstList } = drawChecklistInstanceHelpers;

    if (!options?.isChangesCanBeUndone) {
      const isSuccess = await deleteChecklistInstance({ id });

      // Если удаление не прошло, то блокируем дальнейшее выполнение метода
      if (!isSuccess) {
        return;
      }
    }

    delDrawInst(id);

    const drawInstListWithoutDeleted = [...listOfDrawPointInst].filter(
      drawInst => drawInst.id !== id
    );

    const changedDrawInstList = createChangedDrawInstList(drawInstListWithoutDeleted);

    setIdToDrawInst(changedDrawInstList);

    options?.displayPointList?.(changedDrawInstList);
  };

  saveInstances = async (
    creationTaskId?: string,
    isAddInstancesToStore?: boolean,
    selectedTaskId?: string
  ): Promise<IGetChecklistInstanceByTaskId[]> => {
    const {
      listOfDrawPointInst,
      listOfDrawMachineryInst,
      setIdToDrawInst,
    } = this.checklistInstancesStore;

    const { temporaryTask } = this.tasksStore;
    const { task: taskEdit } = this.tasksEditStore;

    const { isMachinery, isPlan } = checklistInstanceHelpers;
    const { createDrawInstListCol } = drawChecklistInstanceHelpers;

    const taskId = selectedTaskId || temporaryTask?.id || taskEdit?.id;

    if (!taskId) {
      return;
    }

    const instanceList = [...listOfDrawPointInst, ...listOfDrawMachineryInst].map(
      ({ instance }) => instance
    );

    const payload: (IGetChecklistInstanceByTaskId | IPutChecklistInstance)[] = instanceList.map(
      combineInstance => {
        const instance = combineInstance as IGetChecklistInstanceByTaskId;

        if (isMachinery(instance.type)) {
          return instance;
        }

        return {
          ...instance,
          taskId: this.checklistInstancesStore.taskId
            ? this.checklistInstancesStore.taskId
            : creationTaskId,
          planCoords: instance.planCoords
            ? {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: instance.planCoords?.coordinates?.length
                    ? instance.planCoords?.coordinates.slice()
                    : instance.planCoords?.geometry?.coordinates.slice(),
                },
              }
            : undefined,
          intensity: instance?.intensity?.id,
        };
      }
    );

    try {
      const res = await this.axios.api.saveChecklistInstanceList(payload, {
        query: {
          taskId,
        },
      });

      // Если мы получили ответ с ошибкой, то заканчиваем обработку данного метода
      if (!_.isArray(res)) {
        return res;
      }

      if (isAddInstancesToStore) {
        const { pointInstList, machineryInstList } = createDrawInstListCol(res);

        setIdToDrawInst(pointInstList);
        setIdToDrawInst(machineryInstList);
      }

      return res;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  /**
   * @param {string} taskId
   * @param {ReturnType<typeof useInspectionPointsActionsHook>['displayPointList']} [displayPointList]
   * @return {*}  {Promise<IDrawChecklistInstance[]>}
   */
  fetchInstanceList = async (
    taskId: string,
    displayPointList?: ReturnType<typeof useInspectionPointsActionsHook>['displayPointList'],
    isMergeMachineryDisplay?: boolean
  ): Promise<IDrawChecklistInstance[]> => {
    const { setIdToDrawInst } = this.checklistInstancesStore;
    const { fetchChecklistInstanceList } = this.checklistsService;
    const { createDrawInstListCol } = drawChecklistInstanceHelpers;

    const typeList = [
      ECheckListInstanceType.ActualPoint,
      ECheckListInstanceType.PlannedPoint,
      ECheckListInstanceType.Machinery,
      ECheckListInstanceType.Field,
    ];

    const res = await fetchChecklistInstanceList({ taskId, size: 2000, types: typeList });

    // Если мы получили ответ с ошибкой, то заканчиваем обработку данного метода
    if (!_.isArray(res)) {
      return;
    }

    const { pointInstList, machineryInstList } = createDrawInstListCol(
      res,
      isMergeMachineryDisplay
    );

    setIdToDrawInst(pointInstList);

    if (!isMergeMachineryDisplay) {
      setIdToDrawInst(machineryInstList);
    }

    displayPointList?.(pointInstList, { isCleanDisplay: true });

    return pointInstList;
  };

  createMachineryInst = async (taskId: string): Promise<IGetChecklistInstanceByTaskId> => {
    const { getTheBiggestDrawInstPosNum, setDrawInst } = this.checklistInstancesStore;

    const { createDrawInst } = drawChecklistInstanceHelpers;

    const machineryInst: IPutChecklistInstance = {
      taskId,
      type: ECheckListInstanceType.Machinery,
      isActive: true,
      createOrUpdateDate: new Date().toISOString(),
    };

    try {
      const createdInst = await this.axios.api.createChecklistInstance(machineryInst);

      if (!createdInst) {
        return;
      }

      const positionNumber = getTheBiggestDrawInstPosNum() + 1;
      setDrawInst(createDrawInst(createdInst, positionNumber));

      return createdInst;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // Other methods

  get isFetching(): boolean {
    return this.checklistsService.isLoading;
  }

  get isFetchingInstanceList(): boolean {
    return this.checklistsService.instanceListRequestStatus === ERequestStatus.Pending;
  }

  get intensityOptionList(): ISelectOption[] {
    return [
      DEFAULT_INTENSITY_OPTION,
      ...this.checklistInstancesStore.intensityList.map<ISelectOption>(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ];
  }

  get intensityDefaultValue(): ISelectOption {
    const { selectedIntensity } = this.checklistInstancesStore;

    if (selectedIntensity) {
      return { label: selectedIntensity.name, value: selectedIntensity.id };
    }

    return DEFAULT_INTENSITY_OPTION;
  }

  addNewUserDictLinkValue = (
    attr: IChecklistAttributeWithValue,
    partialValue: Partial<IPutChecklistAttributeValue>
  ): void => {
    const { setNewUserDictionaryValue } = this.checklistInstancesStore;

    const { isAddedNewValue } = userDictLinkHelpers;

    const attrId = attr.checklistAttribute.id;

    partialValue?.userDictionaryValues?.forEach(value => {
      const isANewVal = Boolean(value?.clientId);

      if (isANewVal) {
        if (isAddedNewValue(attrId, value)) {
          setNewUserDictionaryValue(attrId, value);
        }
      }
    });
  };

  handleChangeAttributeValue: THandleChangeChecklistAttributeValue = ({
    attributeWithValue,
    value,
    listOfOptionValue,
    partialAverageAttrData,
  }) => {
    const {
      getDrawingStage,
      setDrawingStage,
      getDrawingNestedInstance,
      setDrawingNestedInstance,
      setIdOfUnsavedAttr,
      setIsFormChanged,
    } = this.checklistInstancesStore;

    const {
      isExactlyThisAttr,
      isBelongToTheThisParentAttr,
      isBelongToTheProgenitor,
      getChangedAttr,
      getChangedBoolAttr,
      getChangedParentAttr,
      getChangedParentAttrWithFile,
      getChangedProgenitorAttrWithFile,
    } = checklistAttrChangeHelpers;

    const { isBoolAttr, isUserDictLinkAttr, isFileLinkAttr } = checklistAttrTypeHelpers;

    setIsFormChanged(true);

    const drawingTarget = attributeWithValue?.drawingNestedInstanceId
      ? getDrawingNestedInstance(attributeWithValue?.drawingNestedInstanceId)
      : getDrawingStage(attributeWithValue.stageId);

    if (!drawingTarget) return;

    const changedListOfAttrWithValue = drawingTarget.attributeWithValueList.map(
      stageAttrWithValue => {
        if (isExactlyThisAttr(attributeWithValue, stageAttrWithValue)) {
          if (isBoolAttr(attributeWithValue)) {
            return getChangedBoolAttr(stageAttrWithValue, value);
          }

          return getChangedAttr(
            stageAttrWithValue,
            value,
            listOfOptionValue,
            partialAverageAttrData
          );
        }

        if (isBelongToTheThisParentAttr(attributeWithValue, stageAttrWithValue)) {
          /**
           * Если это отдельно стоящий аттрибут-файл, то производим с ним все манипуляции,
           * как это мы делаем с другими атрибутами НЕ файлами.
           */
          if (isFileLinkAttr(attributeWithValue) && attributeWithValue?.isShowFileAsSeparate) {
            return getChangedParentAttr(
              stageAttrWithValue,
              attributeWithValue,
              value,
              listOfOptionValue
            );
          }

          if (isFileLinkAttr(attributeWithValue)) {
            return getChangedParentAttrWithFile(stageAttrWithValue, value);
          }

          return getChangedParentAttr(
            stageAttrWithValue,
            attributeWithValue,
            value,
            listOfOptionValue
          );
        }

        if (isBelongToTheProgenitor(attributeWithValue, stageAttrWithValue)) {
          if (isFileLinkAttr(attributeWithValue)) {
            return getChangedProgenitorAttrWithFile(stageAttrWithValue, attributeWithValue, value);
          }
        }

        return stageAttrWithValue;
      }
    );

    // Связываем созависимые справочники
    if (
      attributeWithValue.checklistAttribute.attribute.type ===
      EChecklistAttributeType.DictionaryLink
    ) {
      const stageId = attributeWithValue?.drawingNestedInstanceId || attributeWithValue?.stageId;

      const depAttrValue = this.checklistInstancesStore.getDepAttrValue(
        stageId,
        attributeWithValue.checklistAttribute.id
      );

      if (depAttrValue) {
        this.checklistInstancesStore.setDepAttrValue(stageId, {
          checkListAttributeId: attributeWithValue.checklistAttribute.id,
          ...value,
        });
      }
    }

    /**
     * Сохраняем id атрибута в стор, где храним тот атрибут, в которым были произведены изменения,
     * чтобы, перед сменой точки, после нажатия на кнопку "отменить" в отобразившемся
     * модальном окне предупреждения, произвести скролл к последнему измененному
     * атрибуту.
     */
    setIdOfUnsavedAttr(attributeWithValue.checklistAttribute.id);

    /**
     * Если это пользовательский справочник, то делаем проверку, является ли значение
     * новым для добавления и, если так, то добавляем его и сохраняем в sessionStorage
     */
    if (isUserDictLinkAttr(attributeWithValue)) {
      this.addNewUserDictLinkValue(attributeWithValue, value);
    }

    if (attributeWithValue?.drawingNestedInstanceId) {
      setDrawingNestedInstance({
        ...(drawingTarget as IDrawingNestedInstance),
        attributeWithValueList: changedListOfAttrWithValue,
      });
    } else {
      setDrawingStage(attributeWithValue.stageId, {
        ...(drawingTarget as IChecklistDrawingStage),
        attributeWithValueList: changedListOfAttrWithValue,
      });
    }
  };

  selectIntensity = async (payload: TGetChecklistByKeysPayload): Promise<void> => {
    const {
      intensityList,
      setSelectedIntensity,
      clearSelectedIntensity,
    } = this.checklistInstancesStore;

    if (payload.intensityId === DEFAULT_INTENSITY_OPTION.value || payload.intensityId === '') {
      clearSelectedIntensity();
      this.clearStoreAfterChangeIntensity();
      return;
    }

    setSelectedIntensity(intensityList.find(({ id }) => id === payload.intensityId));
    this.clearStoreAfterChangeIntensity();

    const fetchedChecklist = await this.fetchChecklistByKeys(payload);

    if (fetchedChecklist) {
      this.checklistsStore.setSelectedChecklist(fetchedChecklist);
    }
  };

  addDrawingNestedInstance = (
    templateDrawingNestedInstance: IDrawingNestedInstance
  ): {
    listOfInvalidAttrWithValue: IChecklistAttributeWithValue[];
    addedNestedInst: IDrawingNestedInstance | null;
  } => {
    const {
      getAttributeList,
      setDrawingNestedInstance,
      deleteTemplateNestedInstance,
    } = this.checklistInstancesStore;

    // Проверяем на валидность введенных значений
    const {
      attributeWithValueList,
      invalidAttributeWithValueList,
      attributeValueListToSave,
    } = getValidatedAttrWithValueList(templateDrawingNestedInstance.attributeWithValueList);

    if (invalidAttributeWithValueList.length) {
      setDrawingNestedInstance({
        ...templateDrawingNestedInstance,
        attributeWithValueList,
      });

      return {
        listOfInvalidAttrWithValue: invalidAttributeWithValueList,
        addedNestedInst: null,
      };
    }

    const availableAttributeList = getAttributeList(
      templateDrawingNestedInstance.rootChecklistAttribute.attribute.checklistLinkPublicId
    );

    const drawingNestedInstance = generateDrawingNestedInstance({
      rootChecklistAttribute: templateDrawingNestedInstance.rootChecklistAttribute,
      attributeList: availableAttributeList,
      putAttributeValueList: attributeValueListToSave,
      listOfAttrWithVal: attributeWithValueList,
    });

    setDrawingNestedInstance(drawingNestedInstance);

    // Обнуляем значения формы добавления

    this.makeDefaultDrawingNestedInstance(templateDrawingNestedInstance);

    deleteTemplateNestedInstance(templateDrawingNestedInstance.rootChecklistAttribute.id);

    return {
      listOfInvalidAttrWithValue: [],
      addedNestedInst: drawingNestedInstance,
    };
  };

  makeDefaultDrawingNestedInstance = (
    templateDrawingNestedInstance: IDrawingNestedInstance
  ): void => {
    const { getAttributeList, setDrawingNestedInstance } = this.checklistInstancesStore;

    const { rootChecklistAttribute } = templateDrawingNestedInstance;

    const availableAttributeList = getAttributeList(
      rootChecklistAttribute.attribute.checklistLinkPublicId
    );

    const defaultAttributeWithValueList = generateAttributeWithValueList({
      stageId: rootChecklistAttribute.id,
      listOfChecklistAttribute: availableAttributeList,
      drawingNestedInstanceId: templateDrawingNestedInstance.id,
    });

    setDrawingNestedInstance({
      ...templateDrawingNestedInstance,
      attributeWithValueList: defaultAttributeWithValueList,
    });
  };

  getInvalidAttrWithValueListOfNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance
  ): IChecklistAttributeWithValue[] => {
    const { getDrawingNestedInstance, setDrawingNestedInstance } = this.checklistInstancesStore;

    const returnInvalidAttributeWithValueList: IChecklistAttributeWithValue[] = [];

    if (drawingNestedInstance) {
      const blankNestedInstance = getDrawingNestedInstance(drawingNestedInstance.id);

      const {
        invalidAttributeWithValueList,
        attributeWithValueList,
      } = getValidatedAttrWithValueList(blankNestedInstance.attributeWithValueList);

      if (invalidAttributeWithValueList.length) {
        returnInvalidAttributeWithValueList.push(...invalidAttributeWithValueList);

        setDrawingNestedInstance({
          ...blankNestedInstance,
          attributeWithValueList,
        });
      }
    }

    return returnInvalidAttributeWithValueList;
  };

  deleteDrawingNestedInstance = (drawingNestedInstanceId: string): void => {
    this.checklistInstancesStore.deleteDrawingNestedInstance(drawingNestedInstanceId);
  };

  toggleDisplayOfDrawingNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance,
    visibilityValue: boolean
  ): void => {
    const { setDrawingNestedInstance } = this.checklistInstancesStore;

    setDrawingNestedInstance({ ...drawingNestedInstance, isCollapsed: visibilityValue });
  };

  handleDrawingNestedInstanceEditClick = (drawNestedInst: IDrawingNestedInstance): void => {
    const {
      getDrawingNestedInstance,
      getTempNestedInst,
      getEditNestedInst,
    } = this.checklistInstancesStore;

    const tempNestedInst = getTempNestedInst(drawNestedInst.rootChecklistAttribute.id);

    if (tempNestedInst) {
      const tempDrawNestedInst = getDrawingNestedInstance(tempNestedInst.id);

      if (tempDrawNestedInst && !tempDrawNestedInst?.isRootAttributeHidden) {
        const { addedNestedInst } = this.addDrawingNestedInstance(tempDrawNestedInst);

        if (!addedNestedInst) {
          return;
        }
      }
    }

    const editNestedInst = getEditNestedInst(drawNestedInst.rootChecklistAttribute.id);

    if (editNestedInst) {
      const invalidListOfAttrWithValue = this.getInvalidAttrWithValueListOfNestedInstance(
        editNestedInst
      );

      if (!invalidListOfAttrWithValue.length) {
        /**
         * Если запись, что мы редактировали перед нажатием на следующую,
         * имеет все корректно заполненные атрибуты, то сохраняем ее и коллапсим
         */
        this.toggleDisplayOfDrawingNestedInstance(editNestedInst, false);

        this.enableDrawingNestedInstanceEditing(drawNestedInst);
        this.toggleDisplayOfDrawingNestedInstance(drawNestedInst, true);
      }
    } else {
      this.enableDrawingNestedInstanceEditing(drawNestedInst);
      this.toggleDisplayOfDrawingNestedInstance(drawNestedInst, true);
    }
  };

  generateDrawingStageList = async (
    selectedChecklist: IGetChecklist,
    taskId: string
  ): Promise<void> => {
    const {
      selectedInstance,
      selectedIntensity,
      setIdToDrawingStage,
      setFileListByAttrId,
    } = this.checklistInstancesStore;

    const { setFileToFileMapById } = this.checklistFileUploaderStore;

    const fetchedStageList = await this.fetchStageList(selectedChecklist.id);

    const fetchedAttributeList = await this.fetchAttributeList({
      checkListId: selectedChecklist.id,
      taskId,
      phenophaseId: selectedIntensity?.id,
      activeOnly: true,
      size: 2000,
    });

    if (!selectedInstance) return;

    // Пробрасываем значения атрибутов-файлов в стор
    selectedInstance.attributeValues.forEach(attrVal => {
      if (attrVal?.fileValue) {
        setFileListByAttrId(attrVal.checkListAttributeId, attrVal.fileValue);

        attrVal.fileValue.forEach(fileItem => {
          setFileToFileMapById(fileItem.id, fileItem);
        });
      }
    });

    const headStage: IGetChecklistStage = {
      name: selectedChecklist.name,
      order: 0,
      checklistId: selectedChecklist.id,
      id: selectedChecklist.id,
    };

    const availableStageList: IGetChecklistStage[] = [headStage, ...(fetchedStageList || [])];

    const idToDrawingStage = new Map<string, IChecklistDrawingStage>();

    availableStageList.forEach(stage => {
      const drawStage = generateDrawingStage(
        stage,
        fetchedAttributeList || [],
        selectedInstance.attributeValues
      );

      const listOfDepAttrVal = createListOfDepAttrVal(drawStage.attributeWithValueList);

      if (listOfDepAttrVal.length) {
        listOfDepAttrVal.forEach(attrVal => {
          this.checklistInstancesStore.setDepAttrValue(drawStage.id, attrVal);
        });
      }

      idToDrawingStage.set(stage.id, drawStage);
    });

    setIdToDrawingStage(idToDrawingStage);
  };

  generateDrawingNestedInstanceList = async (
    attributeWithValue: IChecklistAttributeWithValue,
    taskId?: string
  ): Promise<void> => {
    const {
      selectedInstance,
      selectedIntensity,
      setAttributeList,
      setDrawingNestedInstanceList,
      setFileListByAttrId,
    } = this.checklistInstancesStore;

    const { setFileToFileMapById } = this.checklistFileUploaderStore;

    const { checklistAttribute } = attributeWithValue;
    const { checklistLinkPublicId } = attributeWithValue.checklistAttribute.attribute;

    if (!checklistLinkPublicId) return;

    const fetchedAttributeList = await this.fetchAttributesByCheckListPublicId(
      checklistLinkPublicId,
      {
        publicId: checklistLinkPublicId,
        activeOnly: true,
        size: 2000,
        phenophaseId: selectedIntensity ? selectedIntensity.id : '',
        taskId,
      }
    );

    if (fetchedAttributeList) {
      setAttributeList(checklistLinkPublicId, fetchedAttributeList);
    }

    const templateDrawingNestedInstance = generateDrawingNestedInstance({
      rootChecklistAttribute: checklistAttribute,
      attributeList: fetchedAttributeList,
      isTemplate: true,
    });

    const listOfDepAttrVal = createListOfDepAttrVal(
      templateDrawingNestedInstance.attributeWithValueList
    );

    if (listOfDepAttrVal.length) {
      listOfDepAttrVal.forEach(attrVal => {
        this.checklistInstancesStore.setDepAttrValue(templateDrawingNestedInstance.id, attrVal);
      });
    }

    const drawingNestedInstanceList: IDrawingNestedInstance[] = [templateDrawingNestedInstance];

    const instanceList =
      selectedInstance?.attributeValues.find(
        attribute =>
          attribute.checkListAttributeId === checklistAttribute.id &&
          attribute?.checkListInstanceValue
      )?.checkListInstanceValue || [];

    instanceList.forEach(instance => {
      // Пробрасываем значения вложенных атрибутов-файлов в стор
      instance.attributeValues.forEach(attrVal => {
        if (attrVal?.fileValue) {
          setFileListByAttrId(attrVal.id, attrVal.fileValue);

          attrVal.fileValue.forEach(fileItem => {
            setFileToFileMapById(fileItem.id, fileItem);
          });
        }
      });

      const drawNestedInst = generateDrawingNestedInstance({
        rootChecklistAttribute: checklistAttribute,
        attributeList: fetchedAttributeList,
        getAttributeValueList: instance.attributeValues,
      });

      // Связываем созависимые справочники
      const nestedInstlistOfDepAttrVal = createListOfDepAttrVal(
        drawNestedInst.attributeWithValueList
      );

      if (nestedInstlistOfDepAttrVal.length) {
        nestedInstlistOfDepAttrVal.forEach(attrVal => {
          this.checklistInstancesStore.setDepAttrValue(drawNestedInst.id, attrVal);
        });
      }

      drawingNestedInstanceList.push(drawNestedInst);
    });

    setDrawingNestedInstanceList(drawingNestedInstanceList);
  };

  getEnumOptionList = (attributeId: string): ISelectOption[] => {
    const { getAttributeEnumList } = this.checklistInstancesStore;

    return getAttributeEnumList(attributeId)?.map<ISelectOption>(enumValue => ({
      label: enumValue.value,
      value: enumValue.id,
    }));
  };

  enableDrawingNestedInstanceEditing = (drawNestedInst: IDrawingNestedInstance): void => {
    const {
      setPreviousStateOfEditableDrawingNestedInstance,
      setEditNestedInst,
    } = this.checklistInstancesStore;

    setEditNestedInst(drawNestedInst);
    setPreviousStateOfEditableDrawingNestedInstance(drawNestedInst);
  };

  disableDrawingNestedInstanceEditing = (attrId: string): void => {
    const {
      delEditNestedInst,
      clearPreviousStateOfEditableDrawingNestedInstance,
    } = this.checklistInstancesStore;

    delEditNestedInst(attrId);
    clearPreviousStateOfEditableDrawingNestedInstance();
  };

  handleHidingTheChecklistLinkAttribute = (config: {
    rootAttributeId: string;
    rootAttributeBooleanValue?: boolean;
    isLeavingThisChecklist?: boolean;
  }): void => {
    const {
      editableDrawingNestedInstance,
      drawingNestedInstanceList,
      previousStateOfEditableDrawingNestedInstance,
      setDrawingNestedInstance,
    } = this.checklistInstancesStore;

    const templateNestedInstance = drawingNestedInstanceList?.find(
      drawingNestedInstance =>
        drawingNestedInstance.rootChecklistAttribute.id === config.rootAttributeId &&
        drawingNestedInstance.isTemplate
    );

    if (templateNestedInstance) {
      setDrawingNestedInstance({
        ...templateNestedInstance,
        isRootAttributeHidden: config?.rootAttributeBooleanValue === false,
      });
    }

    if (editableDrawingNestedInstance) {
      setDrawingNestedInstance(previousStateOfEditableDrawingNestedInstance);

      this.disableDrawingNestedInstanceEditing(config?.rootAttributeId);
    }

    if (config?.isLeavingThisChecklist) {
      this.hideTemplateNestedInstance(config?.rootAttributeId);
    }
  };

  showTemplateNestedInstance = (templateNestedInstance: IDrawingNestedInstance): void => {
    this.checklistInstancesStore.setTemplateNestedInstance(templateNestedInstance);
  };

  hideTemplateNestedInstance = (rootAttributeId: string): void => {
    this.checklistInstancesStore.deleteTemplateNestedInstance(rootAttributeId);
  };

  getModalWarningBeforeChangeInstance = (successHandler: () => void): void => {
    const { setModal } = this.modalsStore;

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeInstance,
        successHandler
      )
    );
  };

  warnBeforeChangingIntensity = (payload: TGetChecklistByKeysPayload) => {
    const { setModal } = this.modalsStore;

    const { setIntensityModalResult, clearIntensityModalResult } = this.checklistInstancesStore;

    clearIntensityModalResult();

    const successHandler = async (): Promise<void> => {
      await this.selectIntensity(payload);

      setIntensityModalResult(true);
    };

    const denyHandler = () => {
      setIntensityModalResult(false);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeChangeIntensity,
        successHandler,
        denyHandler
      )
    );
  };

  warnBeforeLeavingThePage = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const { setFullScreenMode } = this.tasksStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
      setFullScreenMode(null);
    };

    setModal(
      checklistModalUniqueKey,
      displayModalWindow(
        checklistModalUniqueKey,
        EChecklistModalName.WarningBeforeLeavingThePage,
        successHandler
      )
    );
  };

  clearStoreAfterChangedInstance = (): void => {
    this.checklistInstancesStore.clearSelectedInstance();
    this.checklistInstancesStore.clearSelectedIntensity();

    this.checklistInstancesStore.clearIdToDrawingStage();
    this.checklistInstancesStore.clearIdToDrawingNestedInstance();

    this.checklistInstancesStore.clearIdOfUnsavedAttr();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearStoreAfterChangeIntensity = (): void => {
    this.checklistsStore.clearSelectedChecklist();

    this.checklistInstancesStore.clearIdToDrawingStage();
    this.checklistInstancesStore.clearIdToDrawingNestedInstance();

    this.checklistInstancesStore.clearIdOfUnsavedAttr();

    this.checklistFileUploaderStore.clearUploadKeyToFiles();
  };

  clearInstances = (): void => {
    const { clearIdToDrawPointInst, clearIdToDrawMachineryInst } = this.checklistInstancesStore;

    clearIdToDrawPointInst();
    clearIdToDrawMachineryInst();
  };
}
