import { CultureFillEnum } from '../../constants/culture.fill.enum';
import { LabelFieldFillEnum } from '../../constants/label.fill.enum';
import { PopupPages } from '../../constants/popup.pages';

export const VALUES_TO_HIDE_THE_SEARCH_BAR: CultureFillEnum[] = [
  CultureFillEnum.Msavi,
  CultureFillEnum.Ndvi,
  CultureFillEnum.Fill,
];

export const VALUES_TO_SHOW_THE_CULTURE_FILL: PopupPages[] = [
  PopupPages.None,
  PopupPages.Main,
  PopupPages.Seasons,
];

export interface IOption<L, V> {
  label: L;
  value: V;
}

export const COMMON_OPTIONS_SET: IOption<string, CultureFillEnum>[] = [
  { label: 'Культуры', value: CultureFillEnum.Fill },
  { label: 'Без заливки', value: CultureFillEnum.None },
];

export const INDEXES_OPTIONS_SET: IOption<string, CultureFillEnum>[] = [
  { label: 'Индекс MSAVI', value: CultureFillEnum.Msavi },
  { label: 'Индекс NDVI', value: CultureFillEnum.Ndvi },
];

export const SELECTED_FIELD_OPTIONS_SET: IOption<string, CultureFillEnum>[] = [
  { label: 'Индекс MSAVI', value: CultureFillEnum.Msavi },
  { label: 'Индекс NDVI', value: CultureFillEnum.Ndvi },
  { label: 'Культуры', value: CultureFillEnum.Fill },
  { label: 'Без заливки', value: CultureFillEnum.None },
];

export const LABEL_FIELD_FILL_OPTIONS_SET: IOption<string, LabelFieldFillEnum>[] = [
  { label: 'Площадь', value: LabelFieldFillEnum.Area },
  { label: 'Название', value: LabelFieldFillEnum.Name },
  { label: 'Культура', value: LabelFieldFillEnum.Culture },
  { label: 'Без подписи', value: LabelFieldFillEnum.None },
];
