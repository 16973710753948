import { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { TNewDropdownConfig } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';
import { NewDropdown as Dropdown } from '@farmlink/farmik-ui';

import { TChecklistsEnumAttrToDraw as TEnumAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { ChecklistsStore } from '../../../../mobx/stores';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';

interface IProps {
  attrToDraw: TEnumAttrToDraw;
}

const ChecklistsEnumAttr: FC<IProps> = ({
  attrToDraw: {
    id,
    groupId,
    initialModel,
    value,
    options,
    isBlocked,
    validationScheme,
    dependentFileAttrId,
  },
}) => {
  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const isDependentFileEmpty = !dependentFileAttr?.value?.fileValue?.length;

  const hasNativeAutocompleteSupport = initialModel?.dependency?.autoComplete;

  const hasDependency = useMemo(() => {
    return Boolean(initialModel?.dependency?.dependencies?.length);
  }, []);

  const placeholder = useAttrPlaceholder(
    initialModel.attribute.type,
    initialModel.attribute.placeholder
  );

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  // Отвечает за получение списка опций, когда нет зависимостей.
  useEffect(() => {
    if (hasDependency) return;

    checklistsController.fetchEnumList(groupId, id, {
      attributeId: id,
    });
  }, []);

  const dependencyValue = checklistsController.getEnumDependency(groupId, id);

  // Отвечает за получение списка опций, когда есть зависимости.
  useEffect(() => {
    if (!hasDependency) return;
    if (checklistsStore.mode !== EChecklistMode.Edit) return;

    const hasDepVal = Boolean(dependencyValue.value);
    const isNotEditedDepValue = dependencyValue.isNotEdited;
    const instanceHasAlreadyBeenSaved = Boolean(
      checklistInstancesStore.selectedInstance?.isComplete
    );

    // Чтобы не было пустых срабатываний.
    if (!hasDepVal && isNotEditedDepValue) return;

    if (!hasDepVal) {
      checklistsController.changeAttrValue(EAttrType.Enum, groupId, {
        ...value,
        enumValues: [],
      });

      checklistsController.addSelectedSelectOptionList(EAttrType.Enum, groupId, id, []);

      return;
    }

    const checkIfIsAllowAutocomplete = (): boolean => {
      if (!hasNativeAutocompleteSupport) return false;
      if (!instanceHasAlreadyBeenSaved) return true;
      if (isNotEditedDepValue) return false;
    };

    checklistsController.fetchEnumList(
      groupId,
      id,
      {
        attributeId: id,
        dependencyValueId: dependencyValue.value as string,
      },
      { isAllowAutocomplete: checkIfIsAllowAutocomplete() }
    );
  }, [JSON.stringify(dependencyValue)]);

  const handleChange = useCallback<TNewDropdownConfig['field']['onChange']>(
    (newValue, otherData): void => {
      if (initialModel.attribute?.isMultiselect) {
        checklistsController.changeAttrValue(EAttrType.Enum, groupId, {
          ...value,
          enumValues: !otherData?.selectedValueList
            ? []
            : otherData.selectedValueList.map(o => o.value),
        });

        checklistsController.addSelectedSelectOptionList(
          EAttrType.Enum,
          groupId,
          id,
          !otherData?.selectedValueList ? [] : otherData.selectedValueList
        );

        return;
      }

      checklistsController.changeAttrValue(EAttrType.Enum, groupId, {
        ...value,
        enumValues: !newValue ? [] : [newValue],
      });

      checklistsController.addSelectedSelectOptionList(
        EAttrType.Enum,
        groupId,
        id,
        !newValue ? [] : [otherData.option]
      );
    },
    [options.selectOptionList]
  );

  const dropdownConfig: TNewDropdownConfig = {
    field: {
      onChange: handleChange,
      isRequired: initialModel.isRequired,
      defaultValueList: options.selectedSelectOptionList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isFullTextSearch: true,
          },
        },
        multiselect: initialModel.attribute?.isMultiselect ? {} : null,
      },
      placeholder,
    },
    body: {
      optionList: options.selectOptionList,
    },
    visual: {
      label: initialModel.attribute?.name,
      isBlocked,
      tooltip: initialModel.toolTip,
    },
    validation: {
      error: {
        errorList,
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
  };

  return (
    <>
      {visibilityResult.value ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
        >
          <Dropdown config={dropdownConfig} />

          <CSSContainer display={'flex'} justifyContent={'space-between'}>
            <CSSContainer>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <CSSContainer {...(isDependentFileEmpty ? {} : { flex: '1 1 auto' })}>
              {dependentFileAttrId ? (
                <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
              ) : null}
            </CSSContainer>
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsEnumAttr.displayName = 'ChecklistsEnumAttr';

export default observer(ChecklistsEnumAttr);
