import React, { FC, useMemo, useRef, useState } from 'react';
import { Checkbox } from '@farmlink/farmik-ui';
import { Portal, usePortals } from 'react-portal-hook';
import { generatePath, useNavigate } from 'react-router-dom';

import { ReactComponent as MenuSvg } from '../../static/menu.svg';
import { useStore } from '../../../../../shared/utils/IoC';
import { UiStore } from '../../../../stores/ui.store';
import {
  Arrow,
  Content as ToolContent,
  IconWrapper,
  Item,
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../../../shared/components/ToolTip/style';
import { Field } from '../../../../../../api/models/field.model';
import { ReactComponent as AddIcon } from '../../../../../shared/static/add.svg';
import { ReactComponent as BinIcon } from '../../../../../shared/static/bin_red.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/static/edit.svg';
import { FieldsRoute } from '../../fields.route';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { PopupPages } from '../../../../constants/popup.pages';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import { FieldsStore } from '../../stores/fields.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import MapStore, { MapMode } from '../../../../../map/stores/map.store';
import { LeaveUnitModalStore } from '../../../../stores/leave.unit.modal.store';
import { FieldsItemController } from '../../controllers/fields.item.controller';
import { getUniqueCulturesNameList } from '../../utils';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { RenderPolygonOption } from '../../../../../map/consts/enum.render.option';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { Colors } from '../../../../../shared/constans/colors';

import {
  ActiveLine,
  AreaLabel,
  CheckboxWrapper,
  Content,
  ContentInformation,
  CultureLabel,
  CulturePlaceholder,
  FieldName,
  FieldNameTipWrapper,
  LabelsWrapper,
  Line,
  MapImage,
  RightButton,
  RightColumn,
  TipContent,
  TipTail,
  Wrapper,
} from './style';

type FieldItem = {
  isEdit?: boolean;
  isActive?: boolean;
  onClick: (id: string) => void;
  setRef: (id: string, ref: React.ReactNode) => void;
  fieldModel: Field;
  isLast?: boolean;
  dataTestId: string;
  deleteField?: (id) => void;
  deleteFieldFromCurrentSeason?: (id, seasonYear) => void;
  className?: string;
  onEdit: (v: string) => void;
};

export const FieldItem: FC<FieldItem> = ({
  isEdit,
  isActive,
  isLast,
  onClick,
  setRef,
  fieldModel,
  dataTestId,
  deleteField,
  deleteFieldFromCurrentSeason,
  className,
  onEdit,
}) => {
  const portalManager = usePortals();

  const fieldsItemController = useStore(FieldsItemController);

  const ui = useStore(UiStore);
  const seasons = useStore(SeasonsStore);
  const fields = useStore(FieldsStore);
  const leaveUnitModal = useStore(LeaveUnitModalStore);
  const mapStore = useStore(MapStore);
  const organizationsStore = useStore(OrganizationsStore);

  const { setPageState } = ui;
  let ContextMenuXPosition = 0;
  let ContextMenuYPosition = 0;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteFieldFromAllSeasons, setIsDeleteFieldFromAllSeasons] = useState(false);
  const [isCardFocused, setIsCardFocused] = useState<boolean>(false);
  // const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

  const [hasError, setHasError] = useState(null);
  // const session = useStore(SessionStore);
  // const [isFieldNameTipVisible, setIsFieldNameTipVisible] = useState<boolean>(false);
  let isFieldNameTipVisible = false;
  let isFieldCulturesTipVisible = false;
  const labelRef = useRef(null);
  const culturesLabelRef = useRef(null);
  const lockedFieldNameCloseFunc = useRef<() => void>();
  const lockedFieldCulturesCloseFunc = useRef<() => void>();

  const navigate = useNavigate();

  const isShowLeaveUnitModal = () =>
    ui.popupPageState === PopupPages.CultureZone ||
    fields.mapMode === MapMode.Editing ||
    fields.mapMode === MapMode.CZEditing ||
    fields.mapMode === MapMode.Creating;

  const fieldsEditRoute = useMemo(() => {
    const result = generatePath(FieldsRoute.Edit, {
      orgId: organizationsStore.selectedOrganizationId,
      fieldId: fieldModel.id,
    });
    return result;
  }, [fieldModel.id, organizationsStore.selectedOrganizationId]);

  const handleMenuClick = event => {
    event.stopPropagation();

    setIsCardFocused(true);

    if (ui.isTipOpen) {
      ui.closeTip();
      ui.closePortal();
      setIsCardFocused(false);
    } else {
      portalManager.open(
        portal => {
          ui.setPortal(portal);
          return renderContextMenu(portal.close);
        },
        {
          appendTo: ui.tipContainerRef,
        }
      );

      const bounds = event.target.getBoundingClientRect();
      ContextMenuXPosition = bounds.x;
      ContextMenuYPosition = bounds.y;
      ui.openTip(bounds.x + 35, bounds.y - 10);
    }
  };

  const handleFieldNameHover = (event, name) => {
    if (ui.contextMenuCloseFunc) {
      ui.contextMenuCloseFunc();
    }

    portalManager.open(portal => renderFullLabel(portal.close, name), {
      appendTo: ui.tipContainerRef,
    });

    const bounds = event.target.getBoundingClientRect();
    ContextMenuXPosition = bounds.x;
    ContextMenuYPosition = bounds.y;
    ui.openTip(bounds.x, bounds.y);
  };

  const handleFieldCulturesHover = (event, name) => {
    if (ui.contextMenuCloseFunc) {
      ui.contextMenuCloseFunc();
    }

    if (name.length <= 18) return;
    portalManager.open(portal => renderFieldCulturesLabel(portal.close, name), {
      appendTo: ui.tipContainerRef,
    });

    const bounds = event.target.getBoundingClientRect();
    ContextMenuXPosition = bounds.x;
    ContextMenuYPosition = bounds.y;
    ui.openTip(bounds.x, bounds.y);
  };

  const renderFieldName = (name: string) => {
    if (name?.length > 18) {
      isFieldNameTipVisible = true;
      return `${name.substr(0, 18)}...`;
    } else return name;
  };

  const renderFieldCultures = (cultures: string) => {
    if (cultures.length > 18) {
      isFieldCulturesTipVisible = true;
      return `${cultures.substr(0, 18)}...`;
    } else return cultures;
  };

  const renderContextMenu = closeMenu => {
    const handleRef = el => {
      if (!el) {
        return;
      }
      if (ContextMenuYPosition + el.clientHeight > window.innerHeight)
        ui.openTip(ContextMenuXPosition + 35, window.innerHeight - el.clientHeight);

      el.focus();
    };

    ui.setContextMenuCloseFunc(() => {
      closeMenu();
      ui.closeTip();
      setIsCardFocused(false);
      // setIsOpenContextMenu(false);
    });

    const closeContextMenu = () => {
      closeMenu();
      ui.closeTip();
      setIsCardFocused(false);
      // setIsOpenContextMenu(false);
    };

    const onFieldEditClick = () => {
      const showModal = isShowLeaveUnitModal();

      closeContextMenu();
      if (showModal) {
        leaveUnitModal.setDialogSettings({
          actionHandler: () => {
            leaveUnitModal.isShow = false;
            fieldsItemController.goToFieldEditing();
            navigate(fieldsEditRoute);
          },
          isShow: true,
        });
      } else {
        fieldsItemController.goToFieldEditing();
        navigate(fieldsEditRoute);
      }
    };

    const onAddCultureZoneClick = () => {
      const showModal = isShowLeaveUnitModal();
      closeContextMenu();
      if (fieldModel.id !== fields.selectedFieldId) {
        if (showModal) {
          leaveUnitModal.setDialogSettings({
            actionHandler: () => {
              leaveUnitModal.isShow = false;
              ui.setFullWeatherMode(false);
              onClick(fieldModel.id);
              setPageState(PopupPages.CultureZone);
            },
            isShow: true,
          });
        } else {
          ui.setFullWeatherMode(false);
          onClick(fieldModel.id);
          setPageState(PopupPages.CultureZone);
        }
      } else if (
        ui.popupPageState !== PopupPages.CultureZone &&
        ui.popupPageState !== PopupPages.Seasons
      ) {
        ui.setFullWeatherMode(false);
        onClick(fieldModel.id);
        setPageState(PopupPages.CultureZone);
      } else if (ui.popupPageState === PopupPages.Seasons) {
        setPageState(PopupPages.CultureZone);
      }
    };

    return (
      <ToolWrapper
        ref={handleRef}
        onBlur={event => {
          event.stopPropagation();
          closeContextMenu();
        }}
        tabIndex={-1}
        data-test-id={`${dataTestId}context-menu`}
      >
        <ToolContent>
          <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
            <Item onClick={onFieldEditClick}>
              <IconWrapper>
                <EditIcon />
              </IconWrapper>
              <ToolLabel>Редактировать</ToolLabel>
            </Item>
          </ControlAccessRulesWrapper>
          {fieldModel.id === fields.selectedFieldId &&
          ui.popupPageState === PopupPages.CultureZone ? null : (
            <ControlAccessRulesWrapper
              mode={AccessRuleVisibility.Hide}
              actionName="field.editCultureZone"
            >
              <Item onClick={onAddCultureZoneClick}>
                <IconWrapper>
                  <AddIcon />
                </IconWrapper>
                <ToolLabel>Добавить культуру</ToolLabel>
              </Item>
            </ControlAccessRulesWrapper>
          )}
          <ControlAccessRulesWrapper
            mode={AccessRuleVisibility.Hide}
            actionName="field.deleteField"
          >
            <Item
              onClick={e => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
                closeContextMenu();
              }}
            >
              <IconWrapper>
                <BinIcon />
              </IconWrapper>
              <ToolLabel color={Colors.red}>Удалить</ToolLabel>
            </Item>
          </ControlAccessRulesWrapper>
        </ToolContent>
        <Arrow style={{ top: '15px' }} />
      </ToolWrapper>
    );
  };

  const renderFullLabel = (closeLabel, name) => {
    if (!isFieldNameTipVisible) return;

    if (ui.contextMenuCloseFunc) ui.contextMenuCloseFunc();

    const handleRef = el => {
      if (!el) {
        return;
      }
      if (labelRef.current && labelRef.current.clientWidth)
        ui.openTip(
          ContextMenuXPosition + labelRef.current.clientWidth + 10,
          ContextMenuYPosition - 10
        );
    };
    lockedFieldNameCloseFunc.current = closeLabel;

    return (
      <FieldNameTipWrapper ref={handleRef} data-test-id={`${dataTestId}full-label`}>
        <TipContent>
          <p>{name}</p>
        </TipContent>
        <TipTail />
      </FieldNameTipWrapper>
    );
  };

  const renderFieldCulturesLabel = (closeLabel, name) => {
    if (!isFieldCulturesTipVisible) return;

    if (ui.contextMenuCloseFunc) ui.contextMenuCloseFunc();

    const handleRef = el => {
      if (!el) {
        return;
      }
      if (culturesLabelRef.current && culturesLabelRef.current.clientWidth)
        ui.openTip(
          ContextMenuXPosition + culturesLabelRef.current.clientWidth + 10,
          ContextMenuYPosition - 10
        );
    };
    lockedFieldCulturesCloseFunc.current = closeLabel;

    return (
      <FieldNameTipWrapper ref={handleRef} data-test-id={`${dataTestId}cultures-full-label`}>
        <TipContent>
          <p>{name}</p>
        </TipContent>
        <TipTail />
      </FieldNameTipWrapper>
    );
  };

  const cultureLabel = () => {
    const cultures = getUniqueCulturesNameList(fieldModel.cultureZones);

    if (cultures.length === 0) {
      return (
        <CulturePlaceholder data-test-id={`${dataTestId}-culture-no-culture`}>
          Нет культуры
        </CulturePlaceholder>
      );
    }

    if (cultures.length === 1) {
      return (
        <CultureLabel
          ref={culturesLabelRef}
          onMouseOver={e => handleFieldCulturesHover(e, cultures.join(', '))}
          onMouseOut={() => {
            ui.closeTip();
            if (lockedFieldCulturesCloseFunc.current) {
              // @ts-ignore
              lockedFieldCulturesCloseFunc.current();
              lockedFieldCulturesCloseFunc.current = null;
            }
          }}
          data-test-id={`${dataTestId}-culture-culture`}
        >
          {cultures[0]}
        </CultureLabel>
      );
    }

    if (cultures.length > 1) {
      return (
        <CultureLabel
          ref={culturesLabelRef}
          onMouseOver={e => handleFieldCulturesHover(e, cultures.join(', '))}
          onMouseOut={() => {
            ui.closeTip();
            if (lockedFieldCulturesCloseFunc.current) {
              // @ts-ignore
              lockedFieldCulturesCloseFunc.current();
              lockedFieldCulturesCloseFunc.current = null;
            }
          }}
          data-test-id={`${dataTestId}-culture-culture`}
        >
          {renderFieldCultures(cultures.join(', '))}
        </CultureLabel>
      );
    }
  };

  const onFieldClick = () => {
    const showModal = isShowLeaveUnitModal();
    if (showModal && fieldModel.id !== fields.selectedFieldId) {
      leaveUnitModal.setDialogSettings({
        actionHandler: () => {
          leaveUnitModal.isShow = false;
          ui.setPageState(PopupPages.Main);
          ui.setFullWeatherMode(false);
          (async () => {
            await fieldsItemController.fetchFieldsList();
            onClick(fieldModel.id);
          })();
        },
        isShow: true,
      });
    } else {
      ui.setFullWeatherMode(false);
      onClick(fieldModel.id);
    }
  };

  return (
    <>
      <Wrapper
        onClick={onFieldClick}
        isActive={isActive}
        isCardFocused={isCardFocused}
        ref={elem => setRef(fieldModel.id, elem)}
        className={className || ''}
        data-test-id={'fields-list-item'}
      >
        {isActive && <ActiveLine />}
        <Content>
          {isEdit && (
            <CheckboxWrapper>
              <Checkbox
                dataTestId={`${dataTestId}checkbox`}
                onChange={() => 1}
                label={''}
                isMarkOnly={true}
              />
            </CheckboxWrapper>
          )}
          <ContentInformation isEdit={isEdit}>
            <MapImage
              imgUrl={fieldModel?.icon?.downloadUrl || ''}
              data-test-id={`${dataTestId}-map-photo`}
            />
            <LabelsWrapper>
              <FieldName
                ref={labelRef}
                onMouseOver={e => handleFieldNameHover(e, fieldModel.name)}
                onMouseOut={() => {
                  ui.closeTip();
                  if (lockedFieldNameCloseFunc.current) {
                    // @ts-ignore
                    lockedFieldNameCloseFunc.current();
                    lockedFieldNameCloseFunc.current = null;
                  }
                }}
                data-test-id={`${dataTestId}-field-name`}
              >
                {renderFieldName(fieldModel.name)}
              </FieldName>
              {cultureLabel()}
            </LabelsWrapper>
            <RightColumn>
              <AreaLabel data-test-id={`${dataTestId}-area`}>
                {fieldModel.area ? toFixedWithCeilBackEnd(fieldModel.area) : '0'} га
              </AreaLabel>
              <RightButton className="fieldMenuButton">
                <MenuSvg onClick={handleMenuClick} data-test-id={`${dataTestId}-menu-button`} />
              </RightButton>
            </RightColumn>
          </ContentInformation>
        </Content>
        {!isLast ? <Line /> : null}
      </Wrapper>
      {isDeleteModalOpen && (
        <DialogModal
          status={'warning'}
          title={'Вы уверены, что хотите удалить поле? Поле будет удалено из текущего сезона'}
          cancel={{
            name: 'Нет, отменить',
            handler: event => {
              event.stopPropagation();
              setIsDeleteModalOpen(false);
            },
            color: 'default',
          }}
          accept={{
            name: 'Да, удалить',
            handler: async event => {
              event.stopPropagation();
              // todo refactor очень объемный по коду хэндлер, надо упростить и разнести его
              if (isDeleteFieldFromAllSeasons) {
                try {
                  await deleteField(fieldModel.id);
                } catch (e) {
                  setHasError(true);
                } finally {
                  fieldsItemController.resetEditMode(fieldModel.id === fields.selectedFieldId);
                }
              } else {
                const currentSeasonYear = seasons.selectedSeason;
                if (currentSeasonYear) {
                  try {
                    await deleteFieldFromCurrentSeason(fieldModel.id, currentSeasonYear);
                  } catch (e) {
                    setHasError(true);
                  } finally {
                    fieldsItemController.resetEditMode(fieldModel.id === fields.selectedFieldId);
                  }
                }
              }
              if (
                ui.popupPageState === PopupPages.Seasons &&
                fieldModel.id !== fields.selectedFieldId
              ) {
                const field = fields.getSelectedField();
                mapStore.setPolygon(field.geometry.coordinates, {
                  renderOption: RenderPolygonOption.CultureZone,
                  fill: 'EMPTY',
                });
              }
            },
            color: 'secondary',
          }}
          onClose={() => setIsDeleteModalOpen(false)}
          checkbox={{
            name: 'Удалить это поле из всех сезонов',
            handler: () => setIsDeleteFieldFromAllSeasons(!isDeleteFieldFromAllSeasons),
            checked: isDeleteFieldFromAllSeasons,
          }}
        />
      )}
    </>
  );
};
