import { provide, lazyInject } from '../../../../shared/utils/IoC';
import { FieldsStore } from '../../../modules/fields/stores/fields.store';
import { UiStore } from '../../../stores/ui.store';
import { PopupPages } from '../../../constants/popup.pages';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { LabelFieldFillEnum } from '../../../constants/label.fill.enum';
import MapStore, { MapMode } from '../../../../map/stores/map.store';
import {
  COMMON_OPTIONS_SET,
  INDEXES_OPTIONS_SET,
  LABEL_FIELD_FILL_OPTIONS_SET,
  SELECTED_FIELD_OPTIONS_SET,
  VALUES_TO_HIDE_THE_SEARCH_BAR,
  VALUES_TO_SHOW_THE_CULTURE_FILL,
} from '../utils';
import { FieldsController } from '../../../modules/fields/controllers/fields.controller';
import {
  makeAreaTooltip,
  makeCultureTooltip,
  makeEmptyTooltip,
} from '../../../modules/fields/utils';

@provide.singleton()
export class TopPanelControlsController {
  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(FieldsController)
  protected fieldsController: FieldsController;

  @lazyInject(UiStore)
  uiStore: UiStore;

  getCultureFillOptionsSet = () => {
    switch (this.fieldsStore.mapMode) {
      case MapMode.CZEditing:
        return INDEXES_OPTIONS_SET;
      case MapMode.Listing:
        if (this.uiStore.popupPageState === PopupPages.Seasons) {
          return INDEXES_OPTIONS_SET;
        } else {
          return this.fieldsStore.selectedFieldId ? SELECTED_FIELD_OPTIONS_SET : COMMON_OPTIONS_SET;
        }
      default:
        return this.fieldsStore.selectedFieldId ? SELECTED_FIELD_OPTIONS_SET : COMMON_OPTIONS_SET;
    }
  };

  getLabelFieldFillOptionsSet = () => LABEL_FIELD_FILL_OPTIONS_SET;

  getCultureFill = (): CultureFillEnum => this.fieldsStore.showCulturesFillPanel;

  getLabelFieldFill = (): LabelFieldFillEnum => this.fieldsStore.showLabelFieldFill;

  isCultureFillPanel = (): boolean => {
    const isShowPageStateValue = VALUES_TO_SHOW_THE_CULTURE_FILL.some(
      value => value === this.uiStore.popupPageState
    );
    const isListingMapMode = this.fieldsStore.mapMode === MapMode.Listing;
    const isFieldsList = Boolean(this.fieldsStore.fields.length);

    const isCZEditMapMode = this.fieldsStore.mapMode === MapMode.CZEditing;

    return (isListingMapMode && isShowPageStateValue && isFieldsList) || isCZEditMapMode;
  };

  labelSelectAreVisible = () => {
    return (
      this.fieldsStore.showCulturesFillPanel !== CultureFillEnum.Ndvi &&
      this.fieldsStore.showCulturesFillPanel !== CultureFillEnum.Msavi
    );
  };

  showTimeLine = (): boolean => {
    return (
      (this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Ndvi ||
        this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Msavi) &&
      this.fieldsStore.mapMode === MapMode.Listing &&
      (this.uiStore.popupPageState === PopupPages.None ||
        this.uiStore.popupPageState === PopupPages.Main) &&
      Boolean(this.fieldsStore.selectedFieldId)
    );
  };

  setFieldFill = (value: CultureFillEnum): number[] => {
    if (value === CultureFillEnum.None) this.fieldsStore.setNoneFieldFill(true);
    else this.fieldsStore.setNoneFieldFill(false);

    const cultureZonesIds = this.fieldsController.setShowCulturesFillPanel(value);

    // the code below hides the tooltip if selected NDVI or MSAVI
    const polyId = this.fieldsStore.getSelectedField()?.polyId;

    if (polyId) {
      const layer = this.mapStore.getLayer(polyId);

      if (value === CultureFillEnum.Ndvi || value === CultureFillEnum.Msavi) {
        layer?.closeTooltip();
      } else {
        layer?.openTooltip();
      }
    }

    return cultureZonesIds;
  };

  setAreaLabelFieldFill = (): void =>
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.fieldsStore.fields.forEach(({ polyId, area }) =>
      this.mapStore.setTooltipContentForLayer(polyId, makeAreaTooltip(area))
    );

  setCultureLabelFieldFill = (): void =>
    this.fieldsStore.fields.forEach(({ polyId, cultureZones }) =>
      this.mapStore.setTooltipContentForLayer(polyId, makeCultureTooltip(cultureZones))
    );

  setNameLabelFieldFill = (): void =>
    this.fieldsStore.fields.forEach(({ polyId, name }) =>
      this.mapStore.setTooltipContentForLayer(polyId, name)
    );

  setNoneLabelFieldFill = (): void =>
    this.fieldsStore.fields.forEach(({ polyId }) =>
      this.mapStore.setTooltipContentForLayer(polyId, makeEmptyTooltip())
    );

  selectLabelFieldFillValue = (labelFieldFillValue: LabelFieldFillEnum): void => {
    this.fieldsStore.setShowLabelFieldFill(labelFieldFillValue);

    if (this.fieldsStore.fields.length) {
      switch (labelFieldFillValue) {
        case LabelFieldFillEnum.Area:
          this.setAreaLabelFieldFill();
          break;
        case LabelFieldFillEnum.Culture:
          this.setCultureLabelFieldFill();
          break;
        case LabelFieldFillEnum.None:
          this.setNoneLabelFieldFill();
          break;
        default:
          this.setNameLabelFieldFill();
      }
    }
  };

  isGoogleSearchPanel = (): boolean => {
    const isHideValue = VALUES_TO_HIDE_THE_SEARCH_BAR.some(
      value => value === this.fieldsStore.showCulturesFillPanel
    );
    return !(this.fieldsStore.selectedFieldId && isHideValue);
  };
}
