import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TChecklistsStringAttrToDraw as TStringAttrToDraw } from '../../../../models';
import { Input } from '../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsStore } from '../../../../mobx/stores';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';

interface IProps {
  attrToDraw: TStringAttrToDraw;
}

const ChecklistsStringAttr: FC<IProps> = ({
  attrToDraw: {
    id,
    groupId,
    initialModel,
    value,
    isBlocked,
    validationScheme,
    dependentFileAttrId,
  },
}) => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const [enteredValue, setEnteredValue] = useState(value.stringValue);

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const isDependentFileEmpty = !dependentFileAttr?.value?.fileValue?.length;

  const placeholder = useAttrPlaceholder(
    initialModel.attribute.type,
    initialModel.attribute.placeholder
  );

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  useEffect(() => {
    setEnteredValue(value.stringValue);
  }, [value.stringValue]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string): void => {
      checklistsController.changeAttrValue(EAttrType.String, groupId, {
        ...value,
        stringValue: newValue,
      });
    }, 500)
  );

  const handleInputChange = useCallback((newValue: string): void => {
    setEnteredValue(newValue);
    debounceOnChange.current(newValue);
  }, []);

  return (
    <>
      {visibilityResult.value ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
        >
          <Input
            value={enteredValue}
            onChange={handleInputChange}
            label={initialModel.attribute?.name}
            placeholder={placeholder}
            tooltip={initialModel.toolTip}
            isRequired={initialModel.isRequired}
            isBlocked={isBlocked}
            isWithoutErrorText
          />

          <CSSContainer display={'flex'} justifyContent={'space-between'}>
            <CSSContainer>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <CSSContainer {...(isDependentFileEmpty ? {} : { flex: '1 1 auto' })}>
              {dependentFileAttrId ? (
                <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
              ) : null}
            </CSSContainer>
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsStringAttr.displayName = 'ChecklistsStringAttr';

export default observer(ChecklistsStringAttr);
