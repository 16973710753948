import _, { omit } from 'lodash';

import { provide } from '../../../../shared/utils/IoC';
import { RenderPolygonOption } from '../../../../map/consts/enum.render.option';
import { MapMode } from '../../../../map/stores/map.store';
import { PopupPages } from '../../../constants/popup.pages';
import { FieldsErrors } from '../constants/fields.errors';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { timerDelay } from '../../../../shared/utils/timerDelay';
import { Field } from '../../../../../api/models/field.model';

import { FieldsController } from './fields.controller';

@provide.singleton()
export class FieldsEditingController extends FieldsController {
  deleteFieldById = async (id: string) => {
    let fieldId;
    const store = this.fieldsStore;
    try {
      await store.deleteFieldById(id);
      store.idToFields.delete(id);

      const field = store.editableField || store.idToFields.get(id);
      fieldId = field.polyId;
    } catch (e) {
      console.error(e);
    }
    return fieldId;
  };

  changeEditableFieldName = (v: string) => {
    this.fieldsStore.editableField.name = v;
  };

  isFieldChanged = (initialField: Field, editableField: Field) => {
    delete editableField.areaInHectare;
    delete initialField.areaInHectare;

    return !_.isEqual(initialField, editableField);
  };

  saveEditableField = async (id: string, seasonYear: number) => {
    const store = this.fieldsStore;

    if (!this.isFieldChanged(store.initialEditableField, store.editableField)) {
      return;
    }

    if (store.editableField.areaInHectare > 1000) {
      throw new Error(FieldsErrors.AreaTobBig);
    }

    if (store.map.incorrectLayersId.size > 0) {
      throw new Error(FieldsErrors.Intersection);
    }

    try {
      store.isLoading = true;
      const editableFieldWithGeoJSON = store.editableFieldWithGeoJson;

      // @ts-ignore
      await store.axios.api.saveFieldById(
        {
          id,
          ...editableFieldWithGeoJSON,
          // @ts-ignore
          seasonYear,
        },
        { omit: ['id', 'seasonYear'] }
      );
      await store.updateCultureZones(editableFieldWithGeoJSON, seasonYear);
    } catch (e) {
      store.isLoading = false;
      throw new Error(e.response.data.error);
    }
    store.isLoading = false;
  };

  returnToFieldsListing = async () => {
    await this.fetchFieldsList(RenderPolygonOption.View, '', true);

    this.setMapModeValue(MapMode.Listing);
    this.setMapModeValue(MapMode.Listing);
  };

  startEditingMode = async (excludeId: string) => {
    this.uiStore.setPopupPageState(PopupPages.None);
    this.mapStore.clearAll();
    this.setMapModeValue(MapMode.Editing);
    this.mapStore.setMode(MapMode.Editing);

    await this.fetchFieldsList(RenderPolygonOption.Edit, excludeId, true, true);
    await this.fetchFieldById(excludeId);

    this.selectFieldId(excludeId);
  };

  resetEditMode = async (exitWhenFieldDeleted = false) => {
    [
      ...Array.from(this.mapStore.idToPolygon.values()),
      ...Array.from(this.mapStore.idToEditableLayer.values()),
    ].forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.mapStore.idToPolygon.clear();
    this.mapStore.idToEditableLayer.clear();
    await this.fetchFieldsList(RenderPolygonOption.View, '', true);
    this.setMapModeValue(MapMode.Listing);
    this.mapStore.setMode(MapMode.Listing);
    this.mapStore.setViewMode();
    // эта блядская переменная нужна чтобы скидывался заселекченное поле после выхода из редактирования
    if (exitWhenFieldDeleted) {
      this.setNonePageState();
      this.selectFieldId('');
    } else {
      this.setMainPageState();
    }

    const polyId = this.fieldsStore.getSelectedField()?.polyId;

    if (polyId) {
      this.closeFieldTooltip(polyId);

      // making the field outline white
      // Задержка для пересчёта карты после появления блока погоды
      timerDelay(300).then(() => this.mapStore.highlightPolygon(polyId, true));
    }
  };
}
